var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("vue-headful", { attrs: { title: _vm.pageTitle } }),
      _c(
        "div",
        {
          staticClass: "has-text-centered has-background-primary",
          staticStyle: { "margin-bottom": "20px" },
        },
        [
          _c(
            "h1",
            {
              staticClass: "is-size-6 has-text-white",
              staticStyle: { padding: "5px 0px" },
            },
            [_vm._v(_vm._s(_vm.pageheading.toLocaleUpperCase()))]
          ),
        ]
      ),
      _c("div", { staticStyle: { "max-width": "95%", margin: "auto" } }, [
        _vm.isLoading
          ? _c("div", [_c("Loading")], 1)
          : _c(
              "div",
              { attrs: { id: "body-content-area" } },
              [
                _c("div", { attrs: { id: "pagelayout" } }, [
                  _c("div", { staticClass: "questionnaires-header" }, [
                    _c(
                      "div",
                      {
                        staticClass: "column",
                        attrs: { id: "dateRangeSelector" },
                      },
                      [
                        _c("label", [_vm._v("Questionnaire Year:")]),
                        _vm._v("  "),
                        _c(
                          "span",
                          {
                            staticStyle: {
                              "font-size": ".8em",
                              color: "#808080",
                            },
                          },
                          [_vm._v(_vm._s(_vm.yearFilter))]
                        ),
                        _c("br"),
                        _c("kendo-dropdownlist", {
                          attrs: {
                            id: "ddlReportDateRange",
                            name: "ddlReportDateRange",
                            "data-source": _vm.yearRanges,
                            "data-text-field": "text",
                            "data-value-field": "value",
                          },
                          on: { select: _vm.onYearSelect },
                          model: {
                            value: _vm.selectedYear,
                            callback: function ($$v) {
                              _vm.selectedYear = $$v
                            },
                            expression: "selectedYear",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "column",
                        staticStyle: { "text-align": "center" },
                      },
                      [
                        _c("div", { staticClass: "heading" }, [
                          _vm._v(
                            _vm._s(_vm.yearFilter) + " Annual Questionnaires"
                          ),
                        ]),
                        _vm.totalRecords > 0
                          ? _c("div", { staticClass: "subheading" }, [
                              _vm._v(
                                _vm._s(_vm.returned) +
                                  " of " +
                                  _vm._s(_vm.totalRecords) +
                                  " Returned (" +
                                  _vm._s(
                                    _vm._f("formatPercent")(
                                      (_vm.returned / _vm.totalRecords) * 100
                                    )
                                  ) +
                                  ")"
                              ),
                            ])
                          : _vm._e(),
                      ]
                    ),
                    _c("div", { staticClass: "column" }),
                  ]),
                ]),
                _c("div", { staticClass: "grid-header-controls" }, [
                  _c("div", { staticClass: "level-left" }, [
                    _c("div", { staticClass: "control grid-button" }, [
                      _c(
                        "a",
                        {
                          staticClass: "button is-light",
                          on: { click: _vm.clearFilters },
                        },
                        [_c("span", [_vm._v("Clear Filters")])]
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "level-right" }, [
                    _c("div", { staticClass: "control" }, [
                      _c(
                        "a",
                        {
                          staticClass: "button is-accent",
                          on: { click: _vm.getCsvExport },
                        },
                        [
                          _vm._m(0),
                          _c("span", [_vm._v(" Export Questionnaires ")]),
                        ]
                      ),
                    ]),
                  ]),
                ]),
                _c("Grid", {
                  attrs: {
                    filter: _vm.filter,
                    "data-items": _vm.questionnaireItems,
                    "selected-field": _vm.selectedField,
                    sortable: true,
                    sort: _vm.sort,
                    filterable: true,
                    pageable: _vm.pageable,
                    "page-size": _vm.pageSize,
                    skip: _vm.skip,
                    take: _vm.take,
                    total: _vm.totalRecords,
                    columns: _vm.columns,
                  },
                  on: {
                    filterchange: _vm.filterChangeHandler,
                    sortchange: _vm.sortChangeHandler,
                    pagechange: _vm.pageChangeHandler,
                    selectionchange: _vm.onSelectionChange,
                    headerselectionchange: _vm.onHeaderSelectionChange,
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "editTemplate",
                      fn: function (ref) {
                        var props = ref.props
                        return [
                          _c(
                            "td",
                            [
                              _c(
                                "router-link",
                                {
                                  staticClass: "bcc-color",
                                  attrs: {
                                    to: {
                                      name: "EditQuestionnaire",
                                      params: {
                                        id: "" + props.dataItem.id,
                                        model:
                                          "" + JSON.stringify(props.dataItem),
                                        skip: "" + _vm.skip,
                                      },
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(props.dataItem.name) + " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                    {
                      key: "filterSlotTemplate",
                      fn: function (ref) {
                        var props = ref.props
                        var methods = ref.methods
                        return [
                          _c("div", { staticClass: "k-filtercell" }, [
                            _c("div", { staticClass: "k-filtercell-wrapper" }, [
                              _c("input", {
                                staticClass: "k-textbox",
                                attrs: { type: "text", id: "" + props.field },
                                domProps: { value: props.value },
                                on: {
                                  input: function (ev) {
                                    methods.change({
                                      operator: "contains",
                                      field: props.field,
                                      value: ev.target.value,
                                      syntheticEvent: ev,
                                    })
                                  },
                                },
                              }),
                            ]),
                          ]),
                        ]
                      },
                    },
                  ]),
                }),
                _c("div", { staticStyle: { height: "16px" } }, [_vm._v(" ")]),
                _c(
                  "div",
                  {
                    staticStyle: { "padding-top": "20px" },
                    attrs: { id: "buttongroup" },
                  },
                  [
                    _c("div", { staticClass: "level" }, [
                      _c("div", { staticClass: "level-left" }, [
                        _c("div", { staticClass: "field is-grouped" }, [
                          _c("div", { staticClass: "control" }, [
                            _c("a", { staticClass: "button is-accent" }, [
                              _vm._m(1),
                              _c(
                                "span",
                                [
                                  _c(
                                    "router-link",
                                    {
                                      staticClass: "has-text-white",
                                      attrs: {
                                        to: {
                                          name: "NewQuestionnaire",
                                          params: {},
                                        },
                                      },
                                    },
                                    [_vm._v("Add Questionnaire")]
                                  ),
                                ],
                                1
                              ),
                            ]),
                          ]),
                          _c("div", { staticClass: "control" }, [
                            _c(
                              "a",
                              {
                                staticClass: "button is-dark",
                                attrs: { disabled: !_vm.hasSelection },
                                on: { click: _vm.confirmDelete },
                              },
                              [
                                _vm._m(2),
                                _c("span", [
                                  _vm._v(
                                    "Delete Questionnaire" +
                                      _vm._s(_vm.hasPluralSelection ? "s" : "")
                                  ),
                                ]),
                              ]
                            ),
                          ]),
                        ]),
                      ]),
                    ]),
                  ]
                ),
                _vm.showDeleteModal
                  ? _c(
                      "div",
                      {
                        staticClass: "modal is-active",
                        attrs: { id: "delete-modal" },
                      },
                      [
                        _c("div", {
                          staticClass: "modal-background",
                          on: {
                            click: function ($event) {
                              _vm.showDeleteModal = false
                            },
                          },
                        }),
                        _c("div", { staticClass: "modal-card" }, [
                          _c(
                            "header",
                            { staticClass: "modal-card-head has-bg-danger" },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "modal-card-title has-bg-danger",
                                },
                                [_vm._v("Confirm Delete")]
                              ),
                              _c("a", {
                                staticClass: "delete",
                                attrs: { "aria-label": "close" },
                                on: {
                                  click: function ($event) {
                                    _vm.showDeleteModal = false
                                  },
                                },
                              }),
                            ]
                          ),
                          _c("section", { staticClass: "modal-card-body" }, [
                            _vm._m(3),
                            _c("div", { staticClass: "field" }, [
                              _c("div", { staticClass: "control" }, [
                                _c("input", {
                                  directives: [
                                    { name: "focus", rawName: "v-focus" },
                                    {
                                      name: "model",
                                      rawName: "v-model.trim",
                                      value: _vm.confirmationText,
                                      expression: "confirmationText",
                                      modifiers: { trim: true },
                                    },
                                  ],
                                  staticClass: "input",
                                  attrs: {
                                    type: "text",
                                    placeholder:
                                      "type `delete` and then click confirm",
                                  },
                                  domProps: { value: _vm.confirmationText },
                                  on: {
                                    keydown: function ($event) {
                                      if (
                                        !$event.type.indexOf("key") &&
                                        _vm._k(
                                          $event.keyCode,
                                          "enter",
                                          13,
                                          $event.key,
                                          "Enter"
                                        )
                                      ) {
                                        return null
                                      }
                                      $event.preventDefault()
                                      return _vm.deleteIfConfirmed.apply(
                                        null,
                                        arguments
                                      )
                                    },
                                    input: function ($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.confirmationText =
                                        $event.target.value.trim()
                                    },
                                    blur: function ($event) {
                                      return _vm.$forceUpdate()
                                    },
                                  },
                                }),
                              ]),
                            ]),
                          ]),
                          _c("footer", { staticClass: "modal-card-foot" }, [
                            _c(
                              "a",
                              {
                                staticClass: "button is-danger",
                                attrs: {
                                  disabled: _vm.confirmationText !== "delete",
                                },
                                on: { click: _vm.deleteIfConfirmed },
                              },
                              [_vm._m(4), _c("span", [_vm._v("Confirm")])]
                            ),
                            _c(
                              "a",
                              {
                                staticClass: "button",
                                on: {
                                  click: function ($event) {
                                    _vm.showDeleteModal = false
                                  },
                                },
                              },
                              [_vm._v("Cancel")]
                            ),
                          ]),
                        ]),
                      ]
                    )
                  : _vm._e(),
              ],
              1
            ),
      ]),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "icon" }, [
      _c("i", { staticClass: "fal fa-file-csv" }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "icon" }, [
      _c("i", { staticClass: "fal fa-plus-square" }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "icon" }, [
      _c("i", { staticClass: "fal fa-trash-alt" }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "content" }, [
      _vm._v(' Type "'),
      _c("b", [_vm._v("delete")]),
      _vm._v('" to confirm that you want to delete one or more items. '),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "icon" }, [
      _c("i", { staticClass: "fal fa-trash-alt" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }